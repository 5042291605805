import React from 'react';

const Main = () => {
  const linkRef = React.createRef();
  setTimeout(() => {
    if (linkRef.current) {
      linkRef.current.classList.add('shown');
    }
  }, 4000)
  return (
    <div className="container">
      <p className="glitch">
        <span aria-hidden="true">mike kerslake</span>
        mike kerslake
        <span aria-hidden="true">mike kerslake</span>
      </p>
      <div className='profile-container'>
        <span>front end software engineer</span>
        <div className='profile-link-container' ref={linkRef}>
          <a href='https://github.com/theonlymikeever' target='_blank' rel="noreferrer">github &#8594;</a>
          <a href='https://www.linkedin.com/in/michael-kerslake' target='_blank' rel="noreferrer">linkedIn &#8594;</a>
        </div>
      </div>
    </div>
  );
};

export default Main;
