import './App.css';
import Main from './views/main';

function App() {
  return (
    <div className="mainWrapper">
     <Main />
    </div>
  );
}

export default App;
